import React from 'react';
import { createRoot } from 'react-dom/client';
import CookieConsent from 'react-cookie-consent';


const euCookie = () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
        >
            This website uses cookies to enhance the user experience.
        </CookieConsent>
    );
}

const domContainer = document.querySelector('#cookie');
const root = createRoot(domContainer);
root.render(React.createElement(euCookie));

export default euCookie;